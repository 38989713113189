import React, { useState } from "react";
import "../Styles/FreeTrial.css";
import step from "../images/step1.png";
import step2 from "../images/step2.png";

const FreeTrial = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleBack = () => {
    setCurrentPage((prev) => prev - 1);
  };

  return (
    <div className="wholePage">
      {/* left box */}
      <div className="leftBox">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="40"
            viewBox="0 0 150 40"
            fill="none"
          >
            <g clip-path="url(#clip0_2858_1015)">
              <path
                d="M31.0916 10.5021L30.7182 8.22735H26.032V27.9169H31.0916V17.5295C31.0916 13.8969 33.5371 12.539 36.1934 12.539C37.8498 12.539 38.8076 12.98 39.8436 13.8156L42.1264 9.57944C41.0061 8.50009 38.9762 7.70508 36.862 7.70508C34.7478 7.70508 32.6758 8.06486 31.0976 10.5021H31.0916Z"
                fill="white"
              />
              <path
                d="M53.8777 7.82812C47.3664 7.82812 43.3066 12.4589 43.3066 18.0936C43.3066 23.7283 47.2881 28.3591 53.9198 28.3591C60.5515 28.3591 64.5752 23.7631 64.5752 18.0936C64.5752 12.4241 60.3889 7.82812 53.8777 7.82812ZM53.9198 23.885C50.2275 23.885 48.3663 21.088 48.3663 18.0936C48.3663 15.0993 50.2757 12.2616 53.9198 12.2616C57.317 12.2616 59.4734 15.1341 59.4734 18.0936C59.4734 21.0531 57.6061 23.885 53.9198 23.885Z"
                fill="white"
              />
              <path
                d="M83.4001 18.2909C83.4001 21.2852 81.2859 23.8037 78.1778 23.8037C75.0698 23.8037 73.2447 21.529 73.2447 18.5288V8.22852H68.1851V18.5752C68.1851 24.6858 71.7509 28.243 76.6419 28.243C79.503 28.243 81.4485 27.2855 83.5627 25.2893L83.894 27.9645H88.4115V8.22852H83.3941V18.2909H83.4001Z"
                fill="white"
              />
              <path
                d="M104.964 7.78711C102.645 7.78711 99.9039 8.7446 98.3318 10.9439L98.0006 8.22814H93.2722V36.1463H98.3318V25.6023C99.7835 27.761 102.976 28.3993 105.132 28.3993C111.686 28.3993 115.456 23.844 115.456 18.0932C115.456 12.3425 111.27 7.78711 104.97 7.78711H104.964ZM104.59 23.6815C101.151 23.6815 98.7836 21.163 98.7836 18.0874C98.7836 15.0118 100.94 12.4933 104.59 12.4933C108.24 12.4933 110.397 15.0118 110.397 18.0874C110.397 21.163 108.035 23.6815 104.59 23.6815Z"
                fill="white"
              />
              <path d="M124.66 0H119.642V27.9182H124.66V0Z" fill="white" />
              <path
                d="M139.584 7.63086C132.953 7.63086 128.724 11.9425 128.724 17.937C128.724 24.2506 132.91 28.4404 139.958 28.4404C143.066 28.4404 146.589 27.4016 148.746 25.2429L145.511 22.1674C144.349 23.3279 141.903 24.0069 140.036 24.0069C136.47 24.0069 134.272 22.2486 133.94 19.8113H149.902C150.691 11.7046 146.421 7.63086 139.578 7.63086H139.584ZM134.031 15.7783C134.778 13.2249 137.018 11.9425 139.753 11.9425C142.656 11.9425 144.728 13.2191 145.059 15.7783H134.031Z"
                fill="white"
              />
              <path
                d="M22.4368 4.56055H17.3832C17.3832 6.4059 15.8232 7.90887 13.9077 7.90887C12.7813 7.47945 11.5646 7.36919 10.366 7.36919C4.64377 7.36919 -0.000244141 11.205 -0.000244141 17.3561C-0.000244141 23.5073 3.9812 27.2618 10.366 27.2618C12.8958 27.2618 15.5461 28.4979 15.5461 31.2137C15.5461 33.9295 13.2632 35.4499 10.366 35.4499C7.46873 35.4499 5.01722 33.7728 5.01722 31.2137H-0.000244141C-0.000244141 36.5234 4.43296 39.9994 10.366 39.9994C16.299 39.9994 20.6057 36.6453 20.6057 31.2137C20.6057 28.6952 19.7745 26.3392 16.3773 24.7027C19.7323 23.223 20.69 19.7876 20.69 17.3561C20.69 15.1974 20.1118 13.2418 18.5337 11.5241L18.5638 11.4313C20.8948 9.96313 22.4428 7.43883 22.4428 4.56055H22.4368ZM10.366 22.8284C7.42055 22.8284 5.01722 20.7915 5.01722 17.3561C5.01722 13.9208 7.42055 11.8027 10.366 11.8027C13.3114 11.8027 15.6726 13.9614 15.6726 17.3561C15.6726 20.7509 13.2692 22.8284 10.366 22.8284Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2858_1015">
                <rect width="150" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h3>
          Join today for a 15-day free trial and discover how Grouple can
          transform your venue's group booking and guest experience management.
        </h3>

        <div className="insideLeftBox">
          <h2>Online Group Bookings</h2>
          <h4>
            Effortlessly manage group bookings through a user-friendly,
            dedicated microsite.
          </h4>
        </div>
        <div className="insideLeftBox">
          <h2>Dynamic Pricing</h2>
          <h4>
            Offer tiered discounts based on group size to maximize bookings and
            revenue.
          </h4>
        </div>
        <div className="insideLeftBox">
          <h2>Personalized Concierge</h2>
          <h4>
            Provide tailored services, enhancing guest experiences and
            satisfaction.
          </h4>
        </div>
        <div className="insideLeftBox">
          <h2>Data, Reporting, and Analytics</h2>
          <h4>
            Access detailed reports and analytics to make data-driven decisions
            and boost revenue.
          </h4>
        </div>
      </div>

      {/* right box */}
      <div className="rightBox">
        <div className="mobileTopLogo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="111"
            height="30"
            viewBox="0 0 111 30"
            fill="none"
          >
            <g clip-path="url(#clip0_2872_2241)">
              <path
                d="M23.0085 7.87708L22.7322 6.171H19.2644V20.9382H23.0085V13.1476C23.0085 10.4231 24.8182 9.40471 26.7839 9.40471C28.0096 9.40471 28.7183 9.73548 29.485 10.3622L31.1743 7.18507C30.3452 6.37555 28.8431 5.7793 27.2786 5.7793C25.7141 5.7793 24.1808 6.04914 23.013 7.87708H23.0085Z"
                fill="#4A6AFE"
              />
              <path
                d="M39.8702 5.87109C35.0518 5.87109 32.0476 9.34418 32.0476 13.5702C32.0476 17.7962 34.9939 21.2693 39.9014 21.2693C44.8088 21.2693 47.7863 17.8223 47.7863 13.5702C47.7863 9.31807 44.6885 5.87109 39.8702 5.87109ZM39.9014 17.9137C37.169 17.9137 35.7917 15.816 35.7917 13.5702C35.7917 11.3245 37.2047 9.19621 39.9014 9.19621C42.4153 9.19621 44.011 11.3506 44.011 13.5702C44.011 15.7899 42.6292 17.9137 39.9014 17.9137Z"
                fill="#4A6AFE"
              />
              <path
                d="M61.7157 13.7187C61.7157 15.9644 60.1512 17.8533 57.8512 17.8533C55.5512 17.8533 54.2007 16.1472 54.2007 13.8971V6.17188H50.4565V13.9319C50.4565 18.5148 53.0953 21.1828 56.7146 21.1828C58.8318 21.1828 60.2715 20.4646 61.836 18.9675L62.0812 20.9738H65.4241V6.17188H61.7112V13.7187H61.7157Z"
                fill="#4A6AFE"
              />
              <path
                d="M77.6726 5.83984C75.9565 5.83984 73.9285 6.55796 72.7651 8.20746L72.52 6.17061H69.021V27.1092H72.7651V19.2012C73.8393 20.8203 76.2017 21.299 77.7974 21.299C82.6469 21.299 85.4372 17.8825 85.4372 13.5694C85.4372 9.25635 82.3394 5.83984 77.6771 5.83984H77.6726ZM77.3962 17.7606C74.8511 17.7606 73.0994 15.8718 73.0994 13.5651C73.0994 11.2584 74.6951 9.36951 77.3962 9.36951C80.0974 9.36951 81.6931 11.2584 81.6931 13.5651C81.6931 15.8718 79.9458 17.7606 77.3962 17.7606Z"
                fill="#4A6AFE"
              />
              <path d="M92.2481 0H88.5352V20.9386H92.2481V0Z" fill="#4A6AFE" />
              <path
                d="M103.293 5.72266C98.3859 5.72266 95.2568 8.95637 95.2568 13.4522C95.2568 18.1875 98.3547 21.3298 103.57 21.3298C105.87 21.3298 108.477 20.5507 110.073 18.9317L107.679 16.625C106.819 17.4955 105.009 18.0047 103.628 18.0047C100.989 18.0047 99.362 16.686 99.1168 14.858H110.929C111.513 8.77793 108.352 5.72266 103.289 5.72266H103.293ZM99.1837 11.8332C99.7364 9.91822 101.395 8.95637 103.418 8.95637C105.567 8.95637 107.1 9.91387 107.345 11.8332H99.1837Z"
                fill="#4A6AFE"
              />
              <path
                d="M16.6032 3.41992H12.8635C12.8635 4.80393 11.7091 5.93116 10.2916 5.93116C9.45813 5.6091 8.55776 5.52641 7.67076 5.52641C3.43633 5.52641 -0.000244141 8.40324 -0.000244141 13.0166C-0.000244141 17.63 2.94603 20.4459 7.67076 20.4459C9.54282 20.4459 11.504 21.3729 11.504 23.4098C11.504 25.4466 9.81472 26.5869 7.67076 26.5869C5.5268 26.5869 3.71268 25.3291 3.71268 23.4098H-0.000244141C-0.000244141 27.3921 3.28032 29.9991 7.67076 29.9991C12.0612 29.9991 15.2482 27.4835 15.2482 23.4098C15.2482 21.5209 14.6331 19.7539 12.1191 18.5265C14.6019 17.4167 15.3106 14.8402 15.3106 13.0166C15.3106 11.3976 14.8827 9.93087 13.7149 8.64261L13.7371 8.57298C15.4621 7.47186 16.6076 5.57863 16.6076 3.41992H16.6032ZM7.67076 17.1208C5.49114 17.1208 3.71268 15.5931 3.71268 13.0166C3.71268 10.4401 5.49114 8.85152 7.67076 8.85152C9.85038 8.85152 11.5976 10.4706 11.5976 13.0166C11.5976 15.5627 9.81917 17.1208 7.67076 17.1208Z"
                fill="#4A6AFE"
              />
            </g>
            <defs>
              <clipPath id="clip0_2872_2241">
                <rect width="111" height="30" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        {currentPage === 1 && (
          <>
            <h2>Get started for free</h2>
            <h3>Start your trial period</h3>

            <img src={step} alt="" />

            <div className="RightBoxForm">
              <div className="insideFormInput">
                <h5>Business name*</h5>
                <input type="text" placeholder="Buisness name" />
              </div>
              <div className="insideFormInput">
                <h5>Brand/Venue name*</h5>
                <input type="text" placeholder="Brand/Venue name*" />
              </div>

              <div className="insideFormInput2">
                <div>
                  <h5>Industry*</h5>
                  <input type="text" placeholder="Industry*" />
                </div>
                <div>
                  <h5>Country*</h5>
                  <input type="text" placeholder="Country*" />
                </div>
              </div>

              <div className="insideFormInput">
                <h5>Business address*</h5>
                <input type="text" placeholder="Business address*" />
              </div>

              <div className="insideFormInput2">
                <div>
                  <h5>City*</h5>
                  <input type="text" placeholder="City*" />
                </div>
                <div>
                  <h5>Postal Code*</h5>
                  <input type="text" placeholder="Postal Code*" />
                </div>
              </div>

              <div className="insideFormInput">
                <h5>State/Province*</h5>
                <input type="text" placeholder="State/Province*" />
              </div>

              <div>
                <button onClick={handleNext} className="nextBttn">
                  Next
                </button>
              </div>

              <div className="belowButtonText">
                <h4>
                  Already have an account? <span>Log in</span>{" "}
                </h4>
              </div>
            </div>
          </>
        )}

        {currentPage === 2 && (
          <>
            <h2>Get started for free</h2>
            <h3>Start your trial period</h3>
            <img src={step2} alt="Step 2" />
            <div className="RightBoxForm">
              <div className="insideFormInput">
                <h5>Full name*</h5>
                <input type="text" placeholder="Full name" />
              </div>
              <div className="insideFormInput">
                <h5>Email*</h5>
                <input type="email" placeholder="email" />
              </div>
              <div className="insideFormInput">
                <h5>Phone number*</h5>
                <input type="tel" placeholder="Phone" />
              </div>
              {/* Other step 2 content */}
              <div className="formNavigation">
                <button onClick={handleBack} className="backBttn">
                  Back
                </button>
                <button onClick={handleNext} className="getStartbttn">
                  Get started
                </button>
              </div>

              <div className="belowButtonText">
                <h4>
                  Already have an account? <span>Log in</span>{" "}
                </h4>
              </div>
            </div>
          </>
        )}

        {currentPage === 3 && (
          <>
            <h2>Welcome to Grouple</h2>
            <div className="thirdStepBox">
              <h3>
                Thank you for providing your business details. We're excited to
                offer you a free trial of Grouple, where you can manage group
                bookings and elevate guest experiences.
              </h3>

              <h3>
                Your free trial is now active, and your password has been sent
                to your email address. <br />
                Please check your email, if you don’t see it in your primary
                inbox, kindly check your spam or junk folder.
              </h3>
              <h3>
                If you have any questions, our support team is here to help.
                Enjoy your trial!
              </h3>
              <h3>
                Best regards, <br />
                The Grouple Team
              </h3>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FreeTrial;
