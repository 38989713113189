import { BrowserRouter, Route, Routes } from "react-router-dom";
// import About from "./pages/about";
// import Contact from "./pages/contact.jsx";
// import Blog from "./pages/blog.jsx";
import Terms from "./pages/terms.jsx";
import LandingPage from "./pages/landingPage.jsx";
import ScrollToTop from "./components/scroll";
import PrivacyPolicy from "./pages/privacyPolicy.jsx";
// import Demo from './components/Demo.jsx';
// import ProductVideo from "./components/productVideo.jsx";
// import ProductVideoTwo from "./components/productVideoTwo.jsx";
import { BookADemo } from "./pages/BookADemo.jsx";
import Pricing from "./pages/Pricing.jsx";
import AboutUs from "./pages/AboutUs.jsx";
import { MarketPlace } from "./New Components/MarketPlace.jsx";
import { DetailVenue } from "./New Components/DetailVenue.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import FreeTrial from "./pages/FreeTrial.jsx";
import Kyc from "./pages/Kyc.jsx";



function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/bookAdemo" element={<BookADemo />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/exploreVenues" element={<MarketPlace />} />
          <Route path="/detailVenues" element={<DetailVenue />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/freetrial" element={<FreeTrial />} />
          <Route path="/kyc" element={<Kyc />} />

          {/* <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/MerchantDashboardDemo" element={<ProductVideo />} />
        <Route path="/EnduserDemo" element={<ProductVideoTwo />} /> */}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
