import React, { useState, useEffect } from "react";
import "../Styles/Kyc.css";
import bigImage from "../images/kycBig.png";
import { message, Upload } from "antd";
import step1 from "../images/kycStep1.png";
import step2 from "../images/kycStep2.png";
import step3 from "../images/kycStep3.png";

const { Dragger } = Upload;

const Kyc = () => {
  const [screen, setScreen] = useState(1);
  const [previewImages, setPreviewImages] = useState(() => {
    const savedImages = localStorage.getItem("uploadedImages");
    return savedImages ? JSON.parse(savedImages) : [];
  });

  const [previewImages2, setPreviewImages2] = useState(() => {
    const savedImages2 = localStorage.getItem("uploadedImages2");
    return savedImages2 ? JSON.parse(savedImages2) : [];
  });

  const [previewImages3, setPreviewImages3] = useState(() => {
    const savedImages3 = localStorage.getItem("uploadedImages3");
    return savedImages3 ? JSON.parse(savedImages3) : [];
  });

  useEffect(() => {
    localStorage.setItem("uploadedImages", JSON.stringify(previewImages));
  }, [previewImages]);

  useEffect(() => {
    localStorage.setItem("uploadedImages2", JSON.stringify(previewImages2));
  }, [previewImages2]);

  useEffect(() => {
    localStorage.setItem("uploadedImages3", JSON.stringify(previewImages3));
  }, [previewImages3]);

  const props = {
    name: "file",
    action: "https://your-server-endpoint/upload",
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false, // Disable file name display
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          setPreviewImages((prevImages) => [...prevImages, e.target.result]);
        };
        fileReader.readAsDataURL(info.file.originFileObj);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest({ file, onSuccess, onError }) {
      setTimeout(() => {
        if (file) {
          onSuccess("OK");
        } else {
          onError("Upload failed");
        }
      }, 1000);
    },
  };


  const props2 = {
    name: "file",
    action: "https://your-server-endpoint/upload",
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false, // Disable file name display
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          setPreviewImages2((prevImages2) => [...prevImages2, e.target.result]);
        };
        fileReader.readAsDataURL(info.file.originFileObj);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest({ file, onSuccess, onError }) {
      setTimeout(() => {
        if (file) {
          onSuccess("OK");
        } else {
          onError("Upload failed");
        }
      }, 1000);
    },
  };

  const props3 = {
    name: "file",
    action: "https://your-server-endpoint/upload",
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false, // Disable file name display
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          setPreviewImages3((prevImages3) => [...prevImages3, e.target.result]);
        };
        fileReader.readAsDataURL(info.file.originFileObj);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest({ file, onSuccess, onError }) {
      setTimeout(() => {
        if (file) {
          onSuccess("OK");
        } else {
          onError("Upload failed");
        }
      }, 1000);
    },
  };

  const removeImage = (indexToRemove) => {
    setPreviewImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const removeImage2 = (indexToRemove) => {
    setPreviewImages2((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const removeImage3 = (indexToRemove) => {
    setPreviewImages3((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };
  return (
    <div className="outerKyc">
      <div className="topKyc">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="111"
          height="30"
          viewBox="0 0 111 30"
          fill="none"
        >
          <g clip-path="url(#clip0_2729_1125)">
            <path
              d="M23.0085 7.87708L22.7322 6.171H19.2644V20.9382H23.0085V13.1476C23.0085 10.4231 24.8182 9.40471 26.7839 9.40471C28.0096 9.40471 28.7183 9.73548 29.485 10.3622L31.1743 7.18507C30.3452 6.37555 28.8431 5.7793 27.2786 5.7793C25.7141 5.7793 24.1808 6.04914 23.013 7.87708H23.0085Z"
              fill="#4A6AFE"
            />
            <path
              d="M39.8702 5.87109C35.0518 5.87109 32.0476 9.34418 32.0476 13.5702C32.0476 17.7962 34.9939 21.2693 39.9014 21.2693C44.8088 21.2693 47.7863 17.8223 47.7863 13.5702C47.7863 9.31807 44.6885 5.87109 39.8702 5.87109ZM39.9014 17.9137C37.169 17.9137 35.7917 15.816 35.7917 13.5702C35.7917 11.3245 37.2047 9.19621 39.9014 9.19621C42.4153 9.19621 44.011 11.3506 44.011 13.5702C44.011 15.7899 42.6292 17.9137 39.9014 17.9137Z"
              fill="#4A6AFE"
            />
            <path
              d="M61.7156 13.7187C61.7156 15.9644 60.151 17.8533 57.8511 17.8533C55.5511 17.8533 54.2005 16.1472 54.2005 13.8971V6.17188H50.4564V13.9319C50.4564 18.5148 53.0951 21.1828 56.7145 21.1828C58.8317 21.1828 60.2714 20.4646 61.8359 18.9675L62.081 20.9738H65.424V6.17188H61.7111V13.7187H61.7156Z"
              fill="#4A6AFE"
            />
            <path
              d="M77.6726 5.83984C75.9565 5.83984 73.9285 6.55796 72.7651 8.20746L72.52 6.17061H69.021V27.1092H72.7651V19.2012C73.8393 20.8203 76.2017 21.299 77.7974 21.299C82.6469 21.299 85.4372 17.8825 85.4372 13.5694C85.4372 9.25635 82.3394 5.83984 77.6771 5.83984H77.6726ZM77.3962 17.7606C74.8511 17.7606 73.0994 15.8718 73.0994 13.5651C73.0994 11.2584 74.6951 9.36951 77.3962 9.36951C80.0974 9.36951 81.6931 11.2584 81.6931 13.5651C81.6931 15.8718 79.9458 17.7606 77.3962 17.7606Z"
              fill="#4A6AFE"
            />
            <path d="M92.2481 0H88.5352V20.9386H92.2481V0Z" fill="#4A6AFE" />
            <path
              d="M103.293 5.72266C98.3859 5.72266 95.2568 8.95637 95.2568 13.4522C95.2568 18.1875 98.3547 21.3298 103.57 21.3298C105.87 21.3298 108.477 20.5507 110.073 18.9317L107.679 16.625C106.819 17.4955 105.009 18.0047 103.628 18.0047C100.989 18.0047 99.362 16.686 99.1168 14.858H110.929C111.513 8.77793 108.352 5.72266 103.289 5.72266H103.293ZM99.1837 11.8332C99.7364 9.91822 101.395 8.95637 103.418 8.95637C105.567 8.95637 107.1 9.91387 107.345 11.8332H99.1837Z"
              fill="#4A6AFE"
            />
            <path
              d="M16.6033 3.41992H12.8636C12.8636 4.80393 11.7092 5.93116 10.2918 5.93116C9.45826 5.6091 8.55788 5.52641 7.67088 5.52641C3.43645 5.52641 -0.00012207 8.40324 -0.00012207 13.0166C-0.00012207 17.63 2.94615 20.4459 7.67088 20.4459C9.54294 20.4459 11.5042 21.3729 11.5042 23.4098C11.5042 25.4466 9.81484 26.5869 7.67088 26.5869C5.52692 26.5869 3.7128 25.3291 3.7128 23.4098H-0.00012207C-0.00012207 27.3921 3.28045 29.9991 7.67088 29.9991C12.0613 29.9991 15.2483 27.4835 15.2483 23.4098C15.2483 21.5209 14.6332 19.7539 12.1193 18.5265C14.602 17.4167 15.3107 14.8402 15.3107 13.0166C15.3107 11.3976 14.8828 9.93087 13.715 8.64261L13.7373 8.57298C15.4622 7.47186 16.6078 5.57863 16.6078 3.41992H16.6033ZM7.67088 17.1208C5.49126 17.1208 3.7128 15.5931 3.7128 13.0166C3.7128 10.4401 5.49126 8.85152 7.67088 8.85152C9.8505 8.85152 11.5978 10.4706 11.5978 13.0166C11.5978 15.5627 9.8193 17.1208 7.67088 17.1208Z"
              fill="#4A6AFE"
            />
          </g>
          <defs>
            <clipPath id="clip0_2729_1125">
              <rect width="111" height="30" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      {/* screen 1 */}
      {screen === 1 && (
        <>
          <img src={bigImage} alt="" />

          <div className="hoverBox">
            <h3>For an easy form filling process</h3>

            <h4>Please be ready with these documents handy</h4>

            <div className="lineKycBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.99935 18.3327C14.5827 18.3327 18.3327 14.5827 18.3327 9.99935C18.3327 5.41602 14.5827 1.66602 9.99935 1.66602C5.41602 1.66602 1.66602 5.41602 1.66602 9.99935C1.66602 14.5827 5.41602 18.3327 9.99935 18.3327Z"
                  fill="#4A6AFE"
                />
                <path
                  d="M6.45898 9.99896L8.81732 12.3573L13.5423 7.64062"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h2>Business registration number</h2>
            </div>
            <div className="lineKycBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.99935 18.3327C14.5827 18.3327 18.3327 14.5827 18.3327 9.99935C18.3327 5.41602 14.5827 1.66602 9.99935 1.66602C5.41602 1.66602 1.66602 5.41602 1.66602 9.99935C1.66602 14.5827 5.41602 18.3327 9.99935 18.3327Z"
                  fill="#4A6AFE"
                />
                <path
                  d="M6.45898 9.99896L8.81732 12.3573L13.5423 7.64062"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h2>VAT number</h2>
            </div>
            <div className="lineKycBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.99935 18.3327C14.5827 18.3327 18.3327 14.5827 18.3327 9.99935C18.3327 5.41602 14.5827 1.66602 9.99935 1.66602C5.41602 1.66602 1.66602 5.41602 1.66602 9.99935C1.66602 14.5827 5.41602 18.3327 9.99935 18.3327Z"
                  fill="#4A6AFE"
                />
                <path
                  d="M6.45898 9.99896L8.81732 12.3573L13.5423 7.64062"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h2>Bank details</h2>
            </div>

            <button onClick={() => setScreen(2)}>Get Started</button>
          </div>
        </>
      )}

      {/* screen 2 */}
      {screen === 2 && (
        <>
          <div className="secondScreenKyc">
            <div className="innerSecondScreenKyc">
              <div className="leftBoxKyc">
                <img src={step1} alt="" />
              </div>

              <div className="outerRightBox">
                <div className="rightBoxKyc">
                  <h2>Business Information</h2>

                  <div className="fullHeadKyc">
                    <h3>Business name*</h3>
                    <input type="text" placeholder="Business name" />
                  </div>
                  <div className="fullHeadKyc">
                    <h3>Brand/Venue name*</h3>
                    <input type="text" placeholder="Brand/Venue name" />
                  </div>
                  <div className="halfHeadKyc">
                    <div>
                      <h3>Industry*</h3>
                      <input type="text" placeholder="Industry" />
                    </div>
                    <div>
                      <h3>Country*</h3>
                      <input type="text" placeholder="Country" />
                    </div>
                  </div>
                  <div className="fullHeadKyc">
                    <h3>Business address*</h3>
                    <input type="text" placeholder="Business address" />
                  </div>
                  <div className="halfHeadKyc">
                    <div>
                      <h3>City*</h3>
                      <input type="text" placeholder="City" />
                    </div>
                    <div>
                      <h3>Postal Code*</h3>
                      <input type="text" placeholder="Postal Code" />
                    </div>
                  </div>
                  <div className="fullHeadKyc">
                    <h3>State/Province*</h3>
                    <input type="text" placeholder="State/Province" />
                  </div>
                </div>
              </div>
            </div>
            <div className="footerKycfirst">
              <button onClick={() => setScreen(3)}>Next</button>
            </div>
          </div>
        </>
      )}

      {/* screen 3 */}
      {screen === 3 && (
        <>
          <div className="secondScreenKyc">
            <div className="innerSecondScreenKyc">
              <div className="leftBoxKyc">
                <img src={step2} alt="" />
              </div>
              <div className="outerRightBox">
                <div className="rightBoxKyc">
                  <h2>Owner Details</h2>

                  <div className="fullHeadKyc">
                    <h3>Full name*</h3>
                    <input type="text" placeholder="Full name" />
                  </div>
                  <div className="fullHeadKyc">
                    <h3>Work Email*</h3>
                    <input type="email" placeholder="Work Email" />
                  </div>
                  <div className="fullHeadKyc">
                    <h3>Phone number*</h3>
                    <input type="tel" placeholder="Phone number" />
                  </div>
                </div>

                <div className="rightBoxKyc">
                  <h2>
                    Owner Identification <span>(Optional)</span>
                  </h2>
                  <div className="halfHeadKyc">
                    <div>
                      <h3>Document type*</h3>
                      <input type="text" placeholder="Select Document type" />
                    </div>
                    <div>
                      <h3>Identification number*</h3>
                      <input type="text" placeholder="Identification number" />
                    </div>
                  </div>

                  <Dragger {...props} style={{ background: "#EDF0FF" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "2rem",
                        paddingTop: "1rem",
                      }}
                    >
                      <div className="uploadIconKyc">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7.00002 18.0032C5.76323 18.0074 4.57227 17.5354 3.67402 16.6852C3.24091 16.2757 2.89546 15.7826 2.65859 15.2356C2.42172 14.6886 2.29837 14.0993 2.29602 13.5032C2.29602 12.3102 2.79202 11.1652 3.67402 10.3212C4.57227 9.47105 5.76323 8.99911 7.00002 9.00324C7.29502 7.69024 8.15702 6.53724 9.39702 5.79624C10.0209 5.4256 10.7071 5.17178 11.422 5.04724C12.1466 4.91977 12.8881 4.9218 13.612 5.05324C14.334 5.18424 15.02 5.44324 15.632 5.81324C16.2327 6.17361 16.7569 6.64823 17.175 7.21024C17.583 7.76324 17.865 8.38224 18.007 9.03324C18.149 9.68324 18.147 10.3532 18 11.0032H19C19.9283 11.0032 20.8185 11.372 21.4749 12.0284C22.1313 12.6847 22.5 13.575 22.5 14.5032C22.5 15.4315 22.1313 16.3217 21.4749 16.9781C20.8185 17.6345 19.9283 18.0032 19 18.0032H18"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 15L12 12L15 15"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 12V21"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    <p className="ant-upload-text">Upload your document</p>
                    <p className="ant-upload-hint">
                      jpeg, png, or pdf formats only
                    </p>
                  </Dragger>

                  {previewImages.length > 0 && (
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {previewImages.map((image, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            width: "75px",
                            height: "75px",
                          }}
                        >
                          <img
                            src={image}
                            alt={`Preview ${index}`}
                            style={{
                              width: "4.5rem",
                              height: "4.5rem",
                              objectFit: "cover",
                              border: "1px solid #ccc",
                              borderRadius: "0.75rem",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              top: "-0.4rem",
                              right: "-0.3rem",
                              cursor: "pointer",
                              width: "1rem",
                              height: "1rem",
                              borderRadius: "6.25rem",
                              background: "#B8B8B8",
                            }}
                            onClick={() => removeImage(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                            >
                              <path
                                d="M2.91675 2.91602L7.08341 7.08268"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M2.91675 7.08268L7.08341 2.91602"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="footerKyc">
              <button onClick={() => setScreen(2)}>Back</button>
              <button onClick={() => setScreen(4)}>Next</button>
            </div>
          </div>
        </>
      )}

      {/* last screen */}
      {screen === 4 && (
        <>
          <div className="secondScreenKyc">
            <div className="innerSecondScreenKyc">
              <div className="leftBoxKyc">
                <img src={step3} alt="" />
              </div>
              <div className="outerRightBox">
                <div className="rightBoxKyc">
                  <h2>Business Documents</h2>

                  <div className="halfHeadKyc">
                    {/* buisness docuement */}
                    <div>
                      <div style={{ width: "100%" }}>
                        <h3>Business registration number*</h3>
                        <input
                          type="number"
                          placeholder="Business registration number"
                        />
                      </div>
                      <Dragger {...props2} style={{ background: "#EDF0FF" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: "2rem",
                            paddingTop: "1rem",
                          }}
                        >
                          <div className="uploadIconKyc" style={{width:"18%"}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.00002 18.0032C5.76323 18.0074 4.57227 17.5354 3.67402 16.6852C3.24091 16.2757 2.89546 15.7826 2.65859 15.2356C2.42172 14.6886 2.29837 14.0993 2.29602 13.5032C2.29602 12.3102 2.79202 11.1652 3.67402 10.3212C4.57227 9.47105 5.76323 8.99911 7.00002 9.00324C7.29502 7.69024 8.15702 6.53724 9.39702 5.79624C10.0209 5.4256 10.7071 5.17178 11.422 5.04724C12.1466 4.91977 12.8881 4.9218 13.612 5.05324C14.334 5.18424 15.02 5.44324 15.632 5.81324C16.2327 6.17361 16.7569 6.64823 17.175 7.21024C17.583 7.76324 17.865 8.38224 18.007 9.03324C18.149 9.68324 18.147 10.3532 18 11.0032H19C19.9283 11.0032 20.8185 11.372 21.4749 12.0284C22.1313 12.6847 22.5 13.575 22.5 14.5032C22.5 15.4315 22.1313 16.3217 21.4749 16.9781C20.8185 17.6345 19.9283 18.0032 19 18.0032H18"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 15L12 12L15 15"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 12V21"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>

                        <p className="ant-upload-text">Upload your document</p>
                        <p className="ant-upload-hint">
                          jpeg, png, or pdf formats only
                        </p>
                      </Dragger>

                      {previewImages2.length > 0 && (
                        <div
                          style={{
                            marginTop: "1rem",
                            display: "flex",
                            flexDirection:"column",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {previewImages2.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                width: "75px",
                                height: "75px",
                              }}
                            >
                              <img
                                src={image}
                                alt={`Preview ${index}`}
                                style={{
                                  width: "4.5rem",
                                  height: "4.5rem",
                                  objectFit: "cover",
                                  border: "1px solid #ccc",
                                  borderRadius: "0.75rem",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  top: "-0.4rem",
                                  right: "-0.3rem",
                                  cursor: "pointer",
                                  width: "1rem",
                                  height: "1rem",
                                  borderRadius: "6.25rem",
                                  background: "#B8B8B8",
                                }}
                                onClick={() => removeImage2(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                >
                                  <path
                                    d="M2.91675 2.91602L7.08341 7.08268"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M2.91675 7.08268L7.08341 2.91602"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Vat number  */}
                    <div>
                      <div style={{ width: "100%" }}>
                        <h3>VAT number*</h3>
                        <input type="text" placeholder="VAT number" />
                      </div>

                      <Dragger {...props3} style={{ background: "#EDF0FF" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: "2rem",
                            paddingTop: "1rem",
                          }}
                        >
                          <div className="uploadIconKyc" style={{width:"18%"}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.00002 18.0032C5.76323 18.0074 4.57227 17.5354 3.67402 16.6852C3.24091 16.2757 2.89546 15.7826 2.65859 15.2356C2.42172 14.6886 2.29837 14.0993 2.29602 13.5032C2.29602 12.3102 2.79202 11.1652 3.67402 10.3212C4.57227 9.47105 5.76323 8.99911 7.00002 9.00324C7.29502 7.69024 8.15702 6.53724 9.39702 5.79624C10.0209 5.4256 10.7071 5.17178 11.422 5.04724C12.1466 4.91977 12.8881 4.9218 13.612 5.05324C14.334 5.18424 15.02 5.44324 15.632 5.81324C16.2327 6.17361 16.7569 6.64823 17.175 7.21024C17.583 7.76324 17.865 8.38224 18.007 9.03324C18.149 9.68324 18.147 10.3532 18 11.0032H19C19.9283 11.0032 20.8185 11.372 21.4749 12.0284C22.1313 12.6847 22.5 13.575 22.5 14.5032C22.5 15.4315 22.1313 16.3217 21.4749 16.9781C20.8185 17.6345 19.9283 18.0032 19 18.0032H18"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 15L12 12L15 15"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 12V21"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>

                        <p className="ant-upload-text">Upload your document</p>
                        <p className="ant-upload-hint">
                          jpeg, png, or pdf formats only
                        </p>
                      </Dragger>

                      {previewImages3.length > 0 && (
                        <div
                          style={{
                            marginTop: "1rem",
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {previewImages3.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                width: "75px",
                                height: "75px",
                              }}
                            >
                              <img
                                src={image}
                                alt={`Preview ${index}`}
                                style={{
                                  width: "4.5rem",
                                  height: "4.5rem",
                                  objectFit: "cover",
                                  border: "1px solid #ccc",
                                  borderRadius: "0.75rem",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  top: "-0.4rem",
                                  right: "-0.3rem",
                                  cursor: "pointer",
                                  width: "1rem",
                                  height: "1rem",
                                  borderRadius: "6.25rem",
                                  background: "#B8B8B8",
                                }}
                                onClick={() => removeImage3(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                >
                                  <path
                                    d="M2.91675 2.91602L7.08341 7.08268"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M2.91675 7.08268L7.08341 2.91602"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="rightBoxKyc">
                  <h2>Bank Details</h2>
                  <div className="halfHeadKyc">
                    <div>
                      <h3>Bank account number*</h3>
                      <input type="number" placeholder="Bank account number" />
                    </div>
                    <div>
                      <h3>Re-enter bank account number*</h3>
                      <input
                        type="number"
                        placeholder="Re-enter bank account number"
                      />
                    </div>
                  </div>
                  <div className="fullHeadKyc">
                    <h3>Bank name*</h3>
                    <input type="text" placeholder="Bank name" />
                  </div>
                  <div className="halfHeadKyc">
                    <div>
                      <h3>Enter IBAN/SWIFT code*</h3>
                      <input type="text" placeholder="Enter IBAN/SWIFT code" />
                    </div>
                    <div>
                      <h3>Account type*</h3>
                      <input type="text" placeholder="Select account" />
                    </div>
                  </div>
                </div>

                <div className="rightBoxKyc">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      gap: "0.75rem",
                    }}
                  >
                    <input type="checkbox" id="declaration" />

                    <h5>
                      I hereby declare that the information provided is true and
                      accurate to the best of my knowledge.{" "}
                      <span> Terms and Conditions</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerKyc">
              <button onClick={() => setScreen(3)}>Back</button>
              <button onClick={() => alert("Form submitted!")}>Submit</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Kyc;
